import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, fromEvent, timer } from 'rxjs';
import { OrderRoutingService } from '../order-routing.service';
import { debounceTime } from 'rxjs/operators';
declare const $: any;

@Component({
  selector: 'app-order-home',
  templateUrl: './order-home.component.html',
  styleUrls: ['./order-home.component.scss']
})
export class OrderHomeComponent implements OnInit {
  slider: any;
  slidermb: any;
  sliderInitialized = false;
  sliderMbInitialized = false;
  totalSlides$ = new BehaviorSubject<number>(0);
  activeSlideIndex = 0;
  isMenuLoading$ = new BehaviorSubject<boolean>(true);

  constructor(private orderRoutindService: OrderRoutingService) { }

  ngOnInit() {

    const initializeSlider = () => {
      if ($(".hero-slider").length && !this.sliderInitialized) {
        this.sliderInitialized = true;
        this.slider = $(".hero-slider").slick({
          autoplay: false,
          autoplaySpeed: 6000,
          pauseOnHover: true,
          arrows: false,
          dots: false,
          fade: true,
          cssEase: 'linear'
        });
    
        this.totalSlides$.next(this.slider[0].slick.slideCount);
        console.log('Total Slides ' + this.totalSlides$.value);

        this.slider.on('afterChange', (event, slick, currentSlide) => {
          this.activeSlideIndex = currentSlide; 
        });
    
    
        $('.slide-action-left').on('click', () => {
          this.slider.slick('slickPrev');    
          this.activeSlideIndex = this.slider[0].slick.currentSlide;
        });
    
        $('.slide-action-right').on('click', () => {
          this.slider.slick('slickNext');
          this.activeSlideIndex = this.slider[0].slick.currentSlide;
        });
      }
    
      if ($(".hero-slider_mb").length && !this.sliderMbInitialized) {
        console.log('hero-slider_mb');
        this.sliderMbInitialized = true;
        this.slidermb = $(".hero-slider_mb").slick({
          autoplay: false,
          autoplaySpeed: 6000,
          pauseOnHover: true,
          arrows: false,
          dots: false,
          fade: true,
          cssEase: 'linear'
        });
    
        this.totalSlides$.next(this.slidermb[0].slick.slideCount);
        console.log('Total Slides ' + this.totalSlides$.value);     

        this.slidermb.on('afterChange', (event, slick, currentSlide) => {
          this.activeSlideIndex = currentSlide; // Update active slide index on slide change
        });
      }
    };
    
    const destroySlider = () => {
      if (this.slider) {
        this.slider.slick('unslick');
        this.sliderInitialized = false;
      }
    
      if (this.slidermb) {
        this.slidermb.slick('unslick');
        this.sliderMbInitialized = false;
      }
    };

    timer(400).subscribe(x => {
      initializeSlider();   
      timer(2000).subscribe(x => {
        this.isMenuLoading$.next(false);
      })   
    });
    
    fromEvent(window, 'resize').pipe(debounceTime(200)).subscribe(() => {
      destroySlider();
      initializeSlider();
    });
      
    
  }

  goToItemDetails() {
    this.orderRoutindService.goToItemDetails();
  }

  goToSlide(index: number) {
    if (this.slidermb) {
      this.slidermb.slick('slickGoTo', index);
      this.activeSlideIndex = index;
    }
  }

}
