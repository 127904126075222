import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { CustomerSessionService } from '../customer-session.service';
import { Router } from '@angular/router';
import { OrderRoutingService } from '../order-routing.service';
import { CartService } from '../cart.service';
import { CardCaptureComponent } from '../common/card-capture/card-capture.component';
import { AlertPopupComponent } from '../common/alert-popup/alert-popup.component';
import { PaymentService } from '../payment.service';
import { PaymentAction } from '../models/enum';
import { PaymentMode } from '../models/payment-mode';
import { ErrorHandlerService } from '../error-handler.service';
import Swal from 'sweetalert2';
import { UtilService } from '../util.service';
import { SquarePaymentComponent } from '../square-payment/square-payment.component';
import { ToastApplePayService } from '../toast-apple-pay.service';
import { ApplePayLineItem, ApplePaymentData } from '../models/apple-payment-data';
import { TranslateService } from '@ngx-translate/core';
import { SquareApplePayService } from '../square-apple-pay.service';
import { GooglePayLineItem, GooglePaymentData } from '../models/google-payment-data';
import { SquareGooglePayService } from '../square-google-pay.service';

@Component({
    selector: 'reload-wallet',
    templateUrl: './reload-wallet.component.html',
    styleUrls: ['./reload-wallet.component.scss'],
    providers: [SquarePaymentComponent]
})
export class ReloadWalletComponent implements OnInit {
    hasCard: boolean = false;

    amountToLoad: number; //set the default value of "amountToLoad" after "currencySymbol" have the value because ion-select does not load "currencySymbol" otherwise

    walletBalance: number;
    currencySymbol: any;

    cardType: string;
    cardLast4: string;
    cardImgSrc: string;
    cardId: string;

    @ViewChild('reloadAmountEle') private reloadAmountEle: any;
    @ViewChild('cardCapture') cardCapture: CardCaptureComponent;
    @ViewChild('alertPopup') alertPopup: AlertPopupComponent;

    amounts = [];

    isComponentLoading: boolean = false;

    isMobile: boolean;
    isMobileApp: boolean;
    isApplepayAvailable: boolean;
    isGooglepayAvailable: boolean;

    constructor(private dataService: DataService,
        public cartService: CartService,
        private customerSession: CustomerSessionService,
        public router: Router,
        private utilService: UtilService,
        private orderRouting: OrderRoutingService,
        private errorHandlerService: ErrorHandlerService,
        private squarePaymentComponent: SquarePaymentComponent,
        private paymentService: PaymentService,
        private toastApplePayService: ToastApplePayService,
        private squareApplePayService: SquareApplePayService,
        private squareGooglePayService: SquareGooglePayService,
        private translate: TranslateService) 
        {
            this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            let mobileAppVal = sessionStorage.getItem('isMobileApp');
            if (mobileAppVal && mobileAppVal == 'true') {
              this.isMobileApp = true;
            }
            else {
              this.isMobileApp = false;
            }
      
            if (this.isMobile && this.isMobileApp) {
              if (sessionStorage.getItem('isApplePayAvailable') && sessionStorage.getItem('isApplePayAvailable') == 'true') {
                this.isApplepayAvailable = true;
              }
              else {
                this.isApplepayAvailable = false;
              }
            }
            else {
              this.toastApplePayService.checkApplePaySessionAvailable();
              this.isApplepayAvailable = this.toastApplePayService.getApplePayAvailable();
            }

            let paymentSetup = this.customerSession.getPaymentSetupInfo();
         
            if (paymentSetup && !paymentSetup.isApplePayEnabled) {
                this.isApplepayAvailable = false;
            }
        
            if (paymentSetup) {
                this.isGooglepayAvailable = paymentSetup.isGooglePayEnabled;
                this.squareGooglePayService.setGoogleAvailable(this.isGooglepayAvailable);
            }
            console.log('APPLE PAY AVAILABLE: ' + this.isApplepayAvailable);
            console.log('GOOGLE PAY AVAILABLE: ' + this.isGooglepayAvailable);
        }

    async ionViewWillEnter() {
        this.isComponentLoading = true;
        this.customerSession.paymentMode = PaymentMode.WALLET; 
        
        await this.getCard();
        this.getWalletBalance();
        await this.getAmounts();
        this.isComponentLoading = false;
        this.removeExtraSpaceAboveLine();
        
    }

    removeExtraSpaceAboveLine() {

        if (this.reloadAmountEle) {
            if (this.reloadAmountEle.el.shadowRoot.querySelector('.item-native') === null) {
                return;
            }
            this.reloadAmountEle.el.shadowRoot.querySelector('.item-native').setAttribute('style', 'min-height:0;');
        }
        
    }

    async getWalletBalance() {        
        this.dataService.getWalletBalance().subscribe(
            async (result) => {
                console.log(result);
                if (result.isSuccess) {                    
                    this.walletBalance = result.data.walletBalance;
                    this.currencySymbol = result.data.currencySymbol;                    
                } else {
                    //alert(result.message);
                    this.utilService.showSimpleAlert(result.message);
                }                
            },
            (err) => {
                console.log(err);
                this.utilService.showErrorAlert(err);  
            }
        )
    }

    async getAmounts() {
        this.utilService.showLoadingMessage(this.translate.instant('Loading Wallet Options....'));        
        const delay = (ms: number) => new Promise(res => setTimeout(res, ms));        
        return this.dataService.getWalletLoadAmounts(this.customerSession.locationId, this.customerSession.paymentMode).toPromise().then(
            async (result) => {  
                await delay(1000).then(() => {
                    this.utilService.hideLoadingMessage();
                });             
                
                if (result && result.length > 0) {
                    result.forEach(v => this.amounts.push({ value: v }));
                    if (this.amounts.length > 1) {
                        this.amountToLoad = this.amounts[1].value;
                    }
                    else {
                        this.amountToLoad = 0;
                    }
                } else {
                    this.amountToLoad = 0;
                    this.utilService.showErrorAlert(this.translate.instant("Something went wrong. Please try again later."));
                   // this.errorHandlerService.alertMessage("Something went wrong. Please try again later.");
                }

            },
            (err) => {
                Swal.close();
                this.amountToLoad = 0;
                this.errorHandlerService.handleError(err);
            }
        )
    }

    async getCard() {
        this.hasCard = false;       
        this.utilService.showLoadingMessage(this.translate.instant('Preparing Wallet Reload....'));
        return this.dataService.getCard(this.customerSession.locationId, this.customerSession.paymentMode).toPromise().then(
            async (result) => {
                console.log('data received from getCard', result);
                if (result.isSuccess) {
                    if (result.data.cardIsPopulated) {
                        this.hasCard = true;
                        this.cardType = result.data.cardType;
                        this.cardLast4 = result.data.cardLast4;
                        this.cardId = result.data.cardId;
                        // this.hasCard = true;        
                        let imgFile = "";
                        if (this.cardType.toUpperCase() === "VISA")
                            imgFile = "visa.png";
                        else if (this.cardType.toUpperCase() === "MASTERCARD")
                            imgFile = "mastercard.png";
                        else if (this.cardType.toUpperCase() === "AMERICAN_EXPRESS")
                            imgFile = "american-express.png";
                        else
                            imgFile = "payment-card.png";

                        this.cardImgSrc = `/assets/imgs/${imgFile}`;
                    }
                } else {
                    this.hasCard = false;
                }
                const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
                await delay(1000).then(() => {
                    this.utilService.hideLoadingMessage();
                });
                
                console.log("has card: " + this.hasCard)
            },
            (err) => {
                this.utilService.showErrorAlert(err);                     
            }
        )
    }

    ngOnInit() { }


    async loadWalletV2(paymentType: string = null) {
        if (this.amountToLoad == undefined || this.amountToLoad <= 0) {
            this.alertPopup.show(this.translate.instant("Error!"), this.translate.instant("Please select an amount greater than 0."));
            this.amountToLoad = 0;
        } else {
            //set up the payment service action and amount
            this.paymentService.request.action = PaymentAction.LOADWALLET;

            //type/amount
            this.paymentService.request.amount = this.amountToLoad;
            this.paymentService.request.currencySymbol = this.currencySymbol;
            if (paymentType == "googlePay") {
                this.paymentService.request.payWithSavedCreditCard = false;
                this.processGooglePayPaymentV3();
            }
            else if (paymentType == "applePay") {
                this.paymentService.request.payWithSavedCreditCard = false;
                this.processApplePayPaymentV3();
            }
            else if (this.hasCard){
                this.paymentService.request.payWithSavedCreditCard = true;
                this.paymentService.request.squareVerificationToken = await this.getVerificationTokenIfNeeded(this.amountToLoad);
                if (this.paymentService.request.squareVerificationToken == "failed") {
                    this.errorHandlerService.alertMessage(this.translate.instant("Card verification failed."));
                    return;
                }
                this.paymentService.processPayment();
                
            } else {
                //go to payment page if doesnt has card if has card call process payment
                this.customerSession.navigateToPaymentPage();
            }
            
        }
    }

    async getVerificationTokenIfNeeded(amount) {
        let paymentSetup = this.customerSession.getPaymentSetupInfo();
        let verificationToken: string = null;

        if (paymentSetup
            && (paymentSetup.paymentProvider == "Square" || paymentSetup.paymentProvider == "SQUAREPAYMENTS")
            && paymentSetup.square3DSEnabled == true
            && this.cardId) {
            this.squarePaymentComponent.initPayments(paymentSetup.squareApplicationId, paymentSetup.squareLocationId); 
            verificationToken = await this.squarePaymentComponent.verifyBuyer(this.cardId, amount);
            if (verificationToken == null || verificationToken == "") {
                verificationToken = "failed";
            }
        }

        return verificationToken;
    }

    back() {
        this.orderRouting.goToCheckout();
    }

    async removeCard() {
        await this.paymentService.removeCard().then((result) => {
            if (result) {
                this.hasCard = false;
                this.ionViewWillEnter();
            }
        });
    }

    processApplePayPaymentV3() {
        let lineItems: ApplePayLineItem[] = [];
        let currencyCode = sessionStorage.getItem("currencyCode");
        
        if (!currencyCode) {
        currencyCode = 'USD';
        }
        
        var finalTotal = new ApplePayLineItem();   
        finalTotal.type = 'final';  
        finalTotal.label = 'Order Total';
        finalTotal.amount = this.amountToLoad;
        
        var paymentData = new ApplePaymentData();
        paymentData.countryCode = 'US'; 
        paymentData.currencyCode = currencyCode;
        paymentData.lineItems = lineItems;
        paymentData.total = finalTotal; 
        paymentData.supportedNetworks = ['amex', 'discover', 'masterCard', 'visa'];
        paymentData.merchantCapabilities = ['supports3DS'];    
        paymentData.requiredBillingContactFields = ['postalAddress', 'name', 'phone', 'email']; 
        paymentData.ooMerchantLocationId = this.customerSession.locationId;   

        let paymentSetup = this.customerSession.getPaymentSetupInfo();
        if ((paymentSetup.paymentProvider == "Square" || paymentSetup.paymentProvider == "SQUAREPAYMENTS")) {     
            this.squareApplePayService.processApplePayV3(paymentData, paymentSetup.squareApplicationId, paymentSetup.squareLocationId);
        }
        else if (paymentSetup.paymentProvider == "Toast") {      
            this.toastApplePayService.processApplepPayV3(paymentData);
        }
        else {
            console.log("Currently this integration does not support apple payments.");
        }    
    }

    processGooglePayPaymentV3() {
        let lineItems: GooglePayLineItem[] = [];
        let currencyCode = sessionStorage.getItem("currencyCode");
        
        if (!currencyCode) {
        currencyCode = 'USD';
        }
        
        var finalTotal = new GooglePayLineItem();   
        finalTotal.type = 'final';  
        finalTotal.label = 'Order Total';
        finalTotal.amount = this.amountToLoad;
        
        var paymentData = new GooglePaymentData();
        paymentData.countryCode = 'US'; 
        paymentData.currencyCode = currencyCode;
        paymentData.lineItems = lineItems;
        paymentData.total = finalTotal; 
        paymentData.supportedNetworks = ['amex', 'discover', 'masterCard', 'visa'];
        paymentData.merchantCapabilities = ['supports3DS'];    
        paymentData.requiredBillingContactFields = ['postalAddress', 'name', 'phone', 'email']; 
        paymentData.ooMerchantLocationId = this.customerSession.locationId;   

        let paymentSetup = this.customerSession.getPaymentSetupInfo();
        if ((paymentSetup.paymentProvider == "Square" || paymentSetup.paymentProvider == "SQUAREPAYMENTS")) {     
            this.squareApplePayService.processApplePayV3(paymentData, paymentSetup.squareApplicationId, paymentSetup.squareLocationId);
        }
        else if (paymentSetup.paymentProvider == "Toast") {      
            this.toastApplePayService.processApplepPayV3(paymentData);
        }
        else {
            console.log("Currently this integration does not support apple payments.");
        }    
    }
}
