import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sortby',
  templateUrl: './sortby.component.html',
  styleUrls: ['./sortby.component.scss']
})
export class SortbyComponent implements OnInit {

  @Input() data: { sortByOption: string };
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  sortByNearest(option: string) {
    this.activeModal.close(option);
  }

  sortByLastOrdered(option: string) {
    this.activeModal.close(option);
  }

}
