import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-square-payment-popup',
  templateUrl: './square-payment-popup.component.html',
  styleUrls: ['./square-payment-popup.component.scss']
})
export class SquarePaymentPopupComponent implements OnInit {

  iframeUrl: string = environment.SQUARE_WALLET_PAY_END_POINT;
  public sanitizedUrl: SafeResourceUrl;

  constructor(private modalService: NgbModal, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
    console.log(this.sanitizedUrl);
    timer(500).subscribe((x) => {
      let iframe = document.getElementById('squarePaymentIframe') as HTMLIFrameElement;
      var msg = { applePaymentData : "Hello World" }; 
      iframe.contentWindow.postMessage( msg, this.iframeUrl);
    });
  }
  close() {
    this.modalService.dismissAll();
  }

  orderPageLoaded() {

  }
}
