import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingController } from "@ionic/angular";
import { CartService } from "../cart.service";
import { CustomerSessionService } from "../customer-session.service";
import { DataService } from "../data.service";
import { PreparedOrder } from "../models/prepared-order.model";
import { CatalogService } from "../catalog.service";
import { GoogleAnalyticService } from "../google-analytic.service";
import { OrderRoutingService } from "../order-routing.service";
import { AlertController } from "@ionic/angular";
import { CategoryTrackedService } from "../common/category-tracked.service";
import { PaymentService } from '../payment.service';
import { PaymentAction } from '../models/enum';
import { ErrorHandlerService } from '../error-handler.service';
import { PaymentMode } from '../models/payment-mode';
import { UtilService } from "../util.service";
import { environment } from 'src/environments/environment';
import { ToastApplePayService } from "../toast-apple-pay.service";
import { ApplePayLineItem, ApplePaymentData, OOFees } from "../models/apple-payment-data";
import { SquarePaymentComponent } from '../square-payment/square-payment.component';
import { TranslateService } from '@ngx-translate/core';
import { SquareApplePayService } from "../square-apple-pay.service";
import { SquareGooglePayService } from "../square-google-pay.service";
import { GooglePayLineItem, GooglePaymentData } from "../models/google-payment-data";
import { GuestDetails } from "../models/guest-details.model";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../auth/auth.service";
import { GiftCardBalanceRequest } from "../models/gift-card-balance-request";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
  providers: [SquarePaymentComponent]
})

export class CheckoutComponent {
  order: PreparedOrder;

  hasCard: boolean = false;
  cardType: string;
  cardLast4: string;
  cartHasItems: boolean = false;
  cardImgSrc: string;
  cardId: string;

  giftCardId: string;
  giftCardPin: string;
  giftCardBalance: number;
  giftCardApplied: boolean;
  giftCardBalanceApplied: number = 0;

  walletPaymentSupported: boolean = false;
  creditCardPaymentSupported: boolean = false;

  isLoaded: boolean = false;

  customTipAmount: number;
  isTipEnabled: boolean = false;
  tipOption: number;
  btnTipId: string = "";

  walletApplied: boolean = false;
  walletBalance: number;
  orderTotal: number = 0;
  walletBalanceApplied: number;
  orderTotalRemaining: number;
  get walletBalanceAvailable() : number { return this.orderTotal - this.giftCardBalanceApplied; }

  currencySymbol: any;

  showPlaceOrder: boolean;
  showLoadWallet: boolean;
  showPayWithGiftCardButton: boolean;

  showTipContainer: boolean = true;
  showGiftCardPopup: boolean; 
  showGiftCardBalance: boolean;
  giftCardValidationOn: boolean;
  giftCardErrorMessage: string;
  isMobile: boolean;
  isMobileApp: boolean;
  isApplepayAvailable: boolean;
  isGooglepayAvailable: boolean;
  showApplePayButton: boolean;
  showGooglePayButton: boolean;
  isGuest: boolean;
  guestDetails: GuestDetails = new GuestDetails();
  guestPhone: FormControl;
  guestEmail: FormControl;
  guestName: FormControl;
  guestDetailsForm: FormGroup;
  merchantName: string = "us";

  merchantGuid: string;

  siteKey: string = environment.recaptchaSiteKey;

  constructor(
    public cartService: CartService,
    private customerSession: CustomerSessionService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private ga: GoogleAnalyticService,
    private orderRouting: OrderRoutingService,
    private errorHandlerService: ErrorHandlerService,
    private paymentService: PaymentService,
    private toastApplePayService: ToastApplePayService,
    private squarePaymentComponent: SquarePaymentComponent,
    private utilService: UtilService,
    private translate: TranslateService,
    private squareApplePayService: SquareApplePayService,
    private squareGooglePayService: SquareGooglePayService,
    private fb: FormBuilder,
    private authService: AuthService
    ) {
      this.showApplePayButton = true;
      this.showGooglePayButton = true;
      let paymentSetup = this.customerSession.getPaymentSetupInfo();
      let mobileAppResult = this.utilService.checkIfMobileApp();
      this.isMobile = mobileAppResult.isMobile;
      this.isMobileApp = mobileAppResult.isMobileApp;        

      if (this.isMobile && this.isMobileApp) {
        if (sessionStorage.getItem('isApplePayAvailable') && sessionStorage.getItem('isApplePayAvailable') == 'true') {
          this.isApplepayAvailable = true;
        }
        else {
          this.isApplepayAvailable = false;
        }
      }
      else {
        if ((paymentSetup.paymentProvider == "Square" || paymentSetup.paymentProvider == "SQUAREPAYMENTS")) {
          this.squareApplePayService.checkApplePaySessionAvailable();
          this.isApplepayAvailable = this.squareApplePayService.getApplePayAvailable();
        }
        else if (paymentSetup.paymentProvider == "Toast") {
          this.toastApplePayService.checkApplePaySessionAvailable();
          this.isApplepayAvailable = this.toastApplePayService.getApplePayAvailable();
        }       
      }     
      /* Finally Check if APPLE PAY is enabled for the Integration */
      if (paymentSetup && !paymentSetup.isApplePayEnabled) {
        this.isApplepayAvailable = false;
      }
      if (paymentSetup) {
        this.isGooglepayAvailable = paymentSetup.isGooglePayEnabled;
        this.squareGooglePayService.setGoogleAvailable(this.isGooglepayAvailable);
      }
      console.log('APPLE PAY AVAILABLE: ' + this.isApplepayAvailable);
      console.log('GOOGLE PAY AVAILABLE: ' + this.isGooglepayAvailable);
    }

  back() {
    this.orderRouting.goToCart();
  }

  async ionViewWillEnter() {   
    this.toastApplePayService.checkApplePaySessionAvailable();
    this.utilService.documentActivatedRoute(this.activatedRoute);
    this.cartHasItems = this.cartService.getCartCount() > 0;
    this.merchantGuid = this.orderRouting.guid;
    this.isGuest = sessionStorage.getItem("authType") == "GUEST" ? true : false;

    if (this.isGuest) {
      let deliveryDetails = this.cartService.getDeliveryOrderDetails();
      let storedGuestPhone = sessionStorage.getItem("guestPhone");
      let storedGuestEmail = sessionStorage.getItem("guestEmail");
      let storedGuestName = sessionStorage.getItem("guestName");

      this.guestPhone = new FormControl('', [Validators.required, this.utilService.validatePhoneNumber]);
      this.guestEmail = new FormControl('', [Validators.required, Validators.email]);
      this.guestName = new FormControl('', [Validators.required, Validators.minLength(1)]);

      if (deliveryDetails != null && deliveryDetails.phone != null) {
        this.guestPhone.setValue(deliveryDetails.phone);
      } else if (storedGuestPhone != null && storedGuestEmail != undefined) {
        this.guestPhone.setValue(storedGuestPhone);
      }
    
      if (storedGuestEmail != null && storedGuestName != undefined) {
        this.guestEmail.setValue(storedGuestEmail);
      }
      if (storedGuestName != null && storedGuestName != undefined) {
        this.guestName.setValue(storedGuestName);
      }

      this.guestPhone.valueChanges.subscribe((value) => {
        sessionStorage.setItem("guestPhone", value);
      });

      this.guestEmail.valueChanges.subscribe((value) => {
        sessionStorage.setItem("guestEmail", value);
      });

      this.guestName.valueChanges.subscribe((value) => {
        sessionStorage.setItem("guestName", value);
      });


      this.guestDetailsForm = this.fb.group({
        'guestName': this.guestName,
        'guestEmail': this.guestEmail,
        'guestPhone': this.guestPhone
      });
    }

    this.merchantName = sessionStorage.getItem("merchantName");

    if (!this.cartHasItems) {
      this.orderRouting.goToLocation();
    }
    else
    {

      this.order = new PreparedOrder();
      this.cartHasItems = this.cartService.getCartCount() > 0;
      this.cartService.isCheckoutPage = true;
      this.customerSession.paymentMode = PaymentMode.ORDER;
  
      if (this.cartHasItems) {
        this.getStoredCard();    
        this.loadCart();  
      }
      this.ga.sendEvent("cart_viewed", "");

    }   
  }

  test(event: any) {
    console.log(event.target);
    $("button").removeClass("tip-selected");
    $(event.target).addClass("tip-selected");
  }

  addCustomTiptoCart() {
    if (this.customTipAmount > 0) {
      this.tipOption = -2;
      this.cartService.tipOption = this.tipOption;
      this.cartService.tipValue = this.customTipAmount;
      this.loadCart();
      this.customTipAmount = null;
    } else {
      /* document.getElementById('customTip').focus();*/
    }
    this.btnTipId = "btnTip-2";
  }

  getStoredCard() {
    this.dataService.getCard(this.customerSession.locationId, this.customerSession.paymentMode).subscribe(
      (result) => {
        console.log("data received from getCard", result);
        if (result.isSuccess) {
          if (result.data.cardIsPopulated) {
            this.cardType = result.data.cardType;
            this.cardLast4 = result.data.cardLast4;
            this.cardId = result.data.cardId;
            this.hasCard = true;
            let imgFile = "";
            if (this.cardType.toUpperCase() === "VISA") imgFile = "visa.png";
            else if (this.cardType.toUpperCase() === "MASTERCARD")
              imgFile = "mastercard.png";
            else if (this.cardType.toUpperCase() === "AMERICAN_EXPRESS")
              imgFile = "american-express.png";
            else imgFile = "payment-card.png";

            this.cardImgSrc = `/assets/imgs/${imgFile}`;
          } else {
            this.hasCard = false;
          }
        }
      },
      (err) => {
        console.log("get card -- error is: ", err);
      }
    );
  }

  async loadCart() {
    console.log("preparing order");
    this.isLoaded = false;
    this.utilService.showLoadingMessage(this.translate.instant("Preparing Checkout..."));
    (await this.cartService.prepareOrder(this.customerSession.locationId)).subscribe(
      (result) => {
        this.order = this.cartService.order;
        this.currencySymbol = this.order.details.currencySymbol;
        this.walletBalance = this.order.availableWalletBalance;
        this.orderTotal = this.order.details.totalPrice;
        this.showTip();
        this.availablePaymentMethods();

        this.isTipEnabled = this.cartService.isTipEnabled;
        this.tipOption = this.cartService.tipOption;

        this.btnTipId = "btnTip" + this.tipOption;

        this.calculateOrderTotalRemaining();
        this.updateButtonVisibility();

        this.utilService.hideLoadingMessage();
        this.isLoaded = true;
      },
      (err) => {
        this.isLoaded = true;
        this.utilService.hideLoadingMessage();     
        this.errorHandlerService.handleError(err);
      }
    );
  }

  availablePaymentMethods() {
    if (
      this.order.supportedPaymentTypes == null ||
      this.order.supportedPaymentTypes.length == 0
    ) {
      this.walletPaymentSupported = true;
      this.creditCardPaymentSupported = true;
    } else {
      if (this.order.supportedPaymentTypes.indexOf("Wallet") > -1) {
        this.walletPaymentSupported = true;
      } else {
        this.walletPaymentSupported = false;
      }

      if (this.order.supportedPaymentTypes.indexOf("CreditCard") > -1) {
        this.creditCardPaymentSupported = true;
      } else {
        this.creditCardPaymentSupported = false;
      }
    }

    console.log("Wallet Payment Supported: " + this.walletPaymentSupported);
    console.log(
      "Credit Card Payment Supported: " + this.creditCardPaymentSupported
    );
  }

  async placeOrder(paymentType: string = null) {
    //ensure they pick a tip option
    if (this.tipOption == -3) {
        this.errorHandlerService.alertMessage(this.translate.instant("please pick a tip option"));
      return;
    }

    // if guest, check guest details are valid
    if (this.isGuest) {
      if (this.guestName.errors && (this.guestName.hasError('required') || this.guestName.hasError('min'))) {
        this.errorHandlerService.alertMessage(this.translate.instant("Please enter your name."))
         return;
      }

      if (this.guestEmail.errors && (this.guestEmail.hasError('required') || this.guestEmail.hasError('email'))) {
        this.errorHandlerService.alertMessage(this.translate.instant("Please enter a valid email address."))
         return;
      }

      if (this.guestPhone.errors && (this.guestPhone.hasError('required') || this.guestPhone.hasError('validatePhoneNumber'))) {
        this.errorHandlerService.alertMessage(this.translate.instant("Please enter a valid phone number."))
         return;
      }

      if (this.guestDetails.TermsAndPrivacyAccepted == null || this.guestDetails.TermsAndPrivacyAccepted == false) {
        this.errorHandlerService.alertMessage(this.translate.instant("Please accept the terms of service and privacy policy."))
        return;
      }

      this.guestDetails.Name = this.guestName.value;
      this.guestDetails.Email = this.guestEmail.value;
      this.guestDetails.Phone = this.guestPhone.value;
    }

    //added this due to us supporting different payment types
    let hasCard = this.hasCard && this.creditCardPaymentSupported;

    //ensure since we dont ask to check apply wallet that its set to true
    if (
      this.walletPaymentSupported == true &&
      this.creditCardPaymentSupported == false
    ) {
      this.walletApplied = true;
    }

    //start payment service
    this.paymentService.request.action = PaymentAction.PLACEORDER;
    this.paymentService.request.applyWallet = this.walletApplied;
    this.paymentService.request.applyGiftCard = this.giftCardApplied;
    this.paymentService.request.giftCardId = this.giftCardId;
    this.paymentService.request.giftCardPin = this.giftCardPin;
    this.paymentService.request.giftCardBalance = this.giftCardBalance;
    this.paymentService.request.payWithSavedCreditCard = hasCard;
    this.paymentService.request.currencySymbol = this.currencySymbol;

    if (this.isGuest) {
      this.paymentService.request.guestDetails = this.guestDetails;
    }

    if (this.cartService.isDineInOrder) {

        let confirm: boolean = await this.confirmDineIn((sessionStorage.getItem('dinein_confirmation_msg') + "").replace('<TABLE_NAME>', 'Table ' + this.cartService.dineInTableName));
        if (confirm == false) {
            return;
        }
    }

    let requiresVerification = false;
    if (this.isGuest) {
      requiresVerification = true;
      this.paymentService.request.amount = this.orderTotalRemaining; // set this in advance to redirect to payment form after phone verification
      this.guestDetails.OrderGuid = this.cartService.cart.orderId;
      this.guestDetails.MerchantLocationId = this.customerSession.locationId;
      await this.authService.checkGuestPhoneVerification(this.guestDetails).then(
        (result) => {
          if (!result.success || (result.errors != null && result.errors != undefined && result.errors.length > 0)) {
            this.utilService.hideLoadingMessage();  
            this.errorHandlerService.handleError(result.errors[0]);
          }
          else {
            requiresVerification = false;
          }
        }, (err) => {
          this.utilService.hideLoadingMessage(); 
          this.errorHandlerService.handleError(err);
        }
      );
    }

    if (!requiresVerification) {
      if (this.orderTotalRemaining == 0){
        this.paymentService.processPayment();
      } 
      else if (paymentType == "googlePay") {
        this.paymentService.request.payWithSavedCreditCard = false;
        this.processGooglePayPaymentV3();
      }
      else if (paymentType == "applePay") {
        this.paymentService.request.payWithSavedCreditCard = false;
        this.processApplePayPaymentV3();
      }
      else if (hasCard){
        this.paymentService.request.payWithSavedCreditCard = true;
        this.paymentService.request.squareVerificationToken = await this.getVerificationTokenIfNeeded(this.orderTotalRemaining);        
        if (this.paymentService.request.squareVerificationToken == "failed") {
            this.errorHandlerService.alertMessage(this.translate.instant("Card verification failed."));
            return;
        }
        this.paymentService.processPayment();
      } 
      else {     
          this.paymentService.request.amount = this.orderTotalRemaining;
          this.customerSession.navigateToPaymentPage();
      }
    }
  }

  async getVerificationTokenIfNeeded(amount) {
    let paymentSetup = this.customerSession.getPaymentSetupInfo();
    let verificationToken: string = null;

    if (paymentSetup
        && (paymentSetup.paymentProvider == "Square" || paymentSetup.paymentProvider == "SQUAREPAYMENTS")
        && paymentSetup.square3DSEnabled == true
        && this.cardId) {
        this.squarePaymentComponent.initPayments(paymentSetup.squareApplicationId, paymentSetup.squareLocationId); 
        verificationToken = await this.squarePaymentComponent.verifyBuyer(this.cardId, amount);
        if (verificationToken == null || verificationToken == "") {
            verificationToken = "failed";
        }
    }

    return verificationToken;
  }

  goToLoadWallet() {
    this.orderRouting.goToLoadWallet();
  }

  walletChecked(event) {
    this.calculateOrderTotalRemaining();
  }

  calculateOrderTotalRemaining() {
    this.orderTotalRemaining = this.orderTotal;
    this.showApplePayButton = true;
    this.showGooglePayButton = true;

    if (this.giftCardApplied) {
      if (this.giftCardBalance >= this.orderTotalRemaining) {
        this.giftCardBalanceApplied = this.orderTotalRemaining;
        this.walletApplied = false;
        this.walletBalanceApplied = 0;
        this.orderTotalRemaining = 0;
        this.showApplePayButton = false;
        this.showGooglePayButton = false;
      } else {
        this.giftCardBalanceApplied = this.giftCardBalance;
        this.orderTotalRemaining = this.orderTotalRemaining - this.giftCardBalance;
      }
    }
    if (this.walletApplied) {
      if (this.walletBalance >= this.orderTotalRemaining) {
        this.walletBalanceApplied = this.orderTotalRemaining;
        this.orderTotalRemaining = 0;
        this.showApplePayButton = false;
        this.showGooglePayButton = false;
      } else {
        this.walletBalanceApplied = this.walletBalance;
        this.orderTotalRemaining = this.orderTotalRemaining - this.walletBalance;
      }
    } 

  }

  updateButtonVisibility(){
    this.showLoadWallet = false;
    if (this.walletPaymentSupported == true 
      && this.creditCardPaymentSupported == false 
      && this.walletBalance < this.orderTotal) {
        this.showLoadWallet = true;
      }
    this.showPlaceOrder = !this.showLoadWallet;
    this.showPayWithGiftCardButton = this.order.isPaymentWithGiftCardAvailable && !this.giftCardApplied;
  }

  addTip(index) {
    //-1 - no tip
    //-2 - custom -- ask via popup
    console.log(this.order);
    console.log(this.tipOption);
    if (index == this.tipOption) {
      return;
    }

    this.tipOption = index;

    if (
      this.tipOption >= 0 &&
      this.tipOption <= this.order.availableTipableAmounts.length - 1
    ) {
      this.cartService.tipValue = this.order.availableTipableAmounts[
        index
      ].percentage;
    } else {
      this.tipOption = -1; //none
      this.cartService.tipValue = 0; //$0
    }

    //assuming tipoption is good set it for cart service
    this.cartService.tipOption = this.tipOption;
    this.btnTipId = "btnTip" + index;
    console.log("Tip Id : " + this.btnTipId);
    this.loadCart();
  }

  closeCustomTipModal() {
    $("#tipModal").hide();
    $(".content-wrapper").css({ opacity: 1 });
    $("#customAmountError").hide();
  }

  showCustomTipModal() {
    //-2 - custom -- ask via popup
    // this.presentTipPrompt();
    $("#tipModal").show();
    $(".content-wrapper").css({ opacity: 0.5 });
    $("#customTipModalHeader").trigger('focus');    
  }

  addCustomTip() {
    let customAmt = $("#customAmount").val();
    if (Number(customAmt) > 0) {
      this.tipOption = -2;
      this.cartService.tipOption = this.tipOption;
      this.cartService.tipValue = Number(customAmt);
      $("#tipModal").hide();
      $("#customAmount").val("");
      $(".content-wrapper").css({ opacity: 1 });
      this.loadCart().then(() => {
        console.log("cart reloaded....");
        this.btnTipId = "btnTip-2";
      });
    } else {
      $("#customAmountError").show();
    }
  }

  onCustomAmountChange(val: number) {
    if (val > 0) {
      $("#customAmountError").hide();
    } else {
      $("#customAmountError").show();
    }
  }

  async presentTipPrompt() {
    const alert = await this.alertController.create({
      header: this.translate.instant("Tip Amount"),
      inputs: [
        {
          name: this.translate.instant("Tip"),
          type: "number",
        },
      ],
      buttons: [
        {
          text: this.translate.instant("Cancel"),
          role: "cancel",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: this.translate.instant("Ok"),
          handler: (data) => {
            console.log("Confirm Ok");
            console.log(data.Tip);
            this.tipOption = -2;
            this.cartService.tipOption = this.tipOption;
            this.cartService.tipValue = data.Tip;

            this.loadCart().then(() => {
              console.log("cart reloaded....");
              this.btnTipId = "btnTip-2";
            });
          },
        },
      ],
    });

    await alert.present();
    } 

    confirmDineIn(msg: string): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            let alert = await this.alertController.create({
                header: this.translate.instant("Confirm"),
                subHeader: msg,
                cssClass: "alert-customs-dinein", //to place "confirm" button first
                buttons: [
                    {
                        text: this.translate.instant("Confirm"),
                        cssClass: "alertButton2",
                        handler: () => {
                            alert.dismiss().then(() => { resolve(true); });
                            return true;
                        }
                    },
                    {
                        text: this.translate.instant("Cancel"),
                        cssClass: "alertButton2",
                        handler: () => {
                            alert.dismiss().then(() => { resolve(false); });
                            return false;
                        }
                    },
                ],
            });
            alert.present();
        });
    }

  async removeCard() {
        await this.paymentService.removeCard().then((result) => {
            if (result) {
                this.hasCard = false;
                this.ionViewWillEnter();
            }
        });
  }

  showTip() {
    if (this.orderTotal <= 0) {
      this.showTipContainer = false;
    }
  }

  openGiftCardModal() {
    this.giftCardValidationOn = false;
    this.giftCardErrorMessage = "";
    this.giftCardPin = "";
    this.giftCardId = "";
    this.giftCardBalance = 0;
    this.showGiftCardBalance = false;
    this.showGiftCardPopup = true;
  }

  closeGiftCardModal() {
    this.showGiftCardPopup = false;
  }

  async getGiftCardBalance(callback: any = null) {
    this.giftCardValidationOn = true;
    if (!this.order.isPaymentWithGiftCardAvailable || !this.giftCardId || !this.giftCardPin) return;
    
    const loading = await this.loadingController.create({
      message: this.translate.instant('Checking Gift Card....')
    });
    await loading.present();

    await (<any>window).grecaptcha.enterprise.ready(async () => {
      // get action token
      const recaptchaAction = 'GetGiftCardBalance'
      const token = await (<any>window).grecaptcha.enterprise.execute(this.siteKey, {action: recaptchaAction});
      //console.log(token)

      let giftCardBalanceRequest = new GiftCardBalanceRequest();
      giftCardBalanceRequest.locationId = this.customerSession.locationId;
      giftCardBalanceRequest.giftCardId = this.giftCardId;
      giftCardBalanceRequest.giftCardPin = this.giftCardPin;
      giftCardBalanceRequest.token = token;
      giftCardBalanceRequest.recaptchaAction = recaptchaAction;

      
      this.dataService.postGiftCardBalance(giftCardBalanceRequest).subscribe(
        (res) => { 
          if (res.isSuccess) {
            console.log(res.data);
            this.giftCardBalance = res.data.availableBalance ? res.data.availableBalance : 0;
            this.showGiftCardBalance = true;
            this.giftCardErrorMessage = "";
            loading.dismiss();
            if (callback) callback();
          } else {
            this.giftCardErrorMessage = this.translate.instant("Gift Card is not available");
            loading.dismiss();
          }
        },
        (err) => { 
          this.giftCardErrorMessage = this.translate.instant("Gift Card is not available");
          loading.dismiss();
        } 
      );
    });
  }

  applyGiftCardBalance() {
    let scope = this;   
    this.getGiftCardBalance(function() {
      if (scope.giftCardBalance > 0) {
        scope.showGiftCardPopup = false;
        scope.giftCardApplied = true;
        scope.calculateOrderTotalRemaining();
        scope.showPayWithGiftCardButton = false;
      } else {
          scope.giftCardErrorMessage = this.translate.instant("Gift Card has no funds");
      }
    });
  }

  removeGiftCardPayment() {
      this.giftCardApplied = false;
      this.giftCardBalanceApplied = 0;
      this.calculateOrderTotalRemaining();
      this.showPayWithGiftCardButton = true;
  }

  processApplePayPaymentV3() {  
    let lineItems: ApplePayLineItem[] = [];
    let ooFees: OOFees[] = [];
    let currencyCode = sessionStorage.getItem("currencyCode");
    
    if (!currencyCode) {
      currencyCode = 'USD';
    }

    const subTotal: ApplePayLineItem = {
      type: 'final',
      label: 'Subtotal',
      amount: (<any>this.order.details).priceFormatted
    };
    lineItems.push(subTotal);
    const tax: ApplePayLineItem = {
      type: 'final',
      label: 'Tax',
      amount: this.order.details.tax
    };
    lineItems.push(tax);
    const tip: ApplePayLineItem = {
      type: 'final',
      label: 'Tip',
      amount: this.order.details.tip
    };
    lineItems.push(tip);
    var finalTotal = new ApplePayLineItem();   
    finalTotal.type = 'final';  
    finalTotal.label = 'Order Total';
    finalTotal.amount = this.orderTotalRemaining;
    
    var paymentData = new ApplePaymentData();
    paymentData.countryCode = 'US'; 
    paymentData.currencyCode = currencyCode;
    paymentData.lineItems = lineItems;
    paymentData.total = finalTotal; 
    paymentData.supportedNetworks = ['amex', 'discover', 'masterCard', 'visa'];
    paymentData.merchantCapabilities = ['supports3DS'];    
    paymentData.requiredBillingContactFields = ['postalAddress', 'name', 'phone', 'email'];       
    paymentData.fees = ooFees;   
    paymentData.walletBalanceApplied = 0;
    paymentData.ooMerchantLocationId = this.customerSession.locationId;

    if (this.walletApplied) {
      paymentData.walletBalanceApplied = this.walletBalanceApplied;

    } 

    if (this.giftCardApplied) {
      paymentData.giftCardBalanceApplied = this.giftCardBalanceApplied;
    } 
    
    if (this.order.details && (<any>this.order.details).fees && (<any>this.order.details).fees.length > 0) {
      (<any>this.order.details).fees.forEach(element => {
          let fee = new OOFees();
          fee.feeType = element.feeType;          
          fee.amount = element.amount;
          fee.name = element.name;
          ooFees.push(fee);
      });
      paymentData.fees = ooFees;
    }


    let paymentSetup = this.customerSession.getPaymentSetupInfo();
    if ((paymentSetup.paymentProvider == "Square" || paymentSetup.paymentProvider == "SQUAREPAYMENTS")) {     
      this.squareApplePayService.processApplePayV3(paymentData, paymentSetup.squareApplicationId, paymentSetup.squareLocationId);
    }
    else if (paymentSetup.paymentProvider == "Toast") {      
      this.toastApplePayService.processApplepPayV3(paymentData);
    }
    else {
      console.log("Currently this integration does not support apple payments.");
    }
  }

  processGooglePayPaymentV3() {
    let lineItems: GooglePayLineItem[] = [];
    let ooFees: OOFees[] = [];
    let currencyCode = sessionStorage.getItem("currencyCode");
    
    if (!currencyCode) {
      currencyCode = 'USD';
    }

    const subTotal: GooglePayLineItem = {
      type: 'final',
      label: 'Subtotal',
      amount: (<any>this.order.details).priceFormatted
    };
    lineItems.push(subTotal);
    const tax: GooglePayLineItem = {
      type: 'final',
      label: 'Tax',
      amount: this.order.details.tax
    };
    lineItems.push(tax);
    const tip: GooglePayLineItem = {
      type: 'final',
      label: 'Tip',
      amount: this.order.details.tip
    };
    lineItems.push(tip);
    var finalTotal = new GooglePayLineItem();   
    finalTotal.type = 'final';  
    finalTotal.label = 'Order Total';
    finalTotal.amount = this.orderTotalRemaining;
    
    var paymentData = new GooglePaymentData();
    paymentData.countryCode = 'US'; 
    paymentData.currencyCode = currencyCode;
    paymentData.lineItems = lineItems;
    paymentData.total = finalTotal; 
    paymentData.supportedNetworks = ['amex', 'discover', 'masterCard', 'visa'];
    paymentData.merchantCapabilities = ['supports3DS'];    
    paymentData.requiredBillingContactFields = ['postalAddress', 'name', 'phone', 'email'];       
    paymentData.fees = ooFees;
    paymentData.walletBalanceApplied = 0;
    paymentData.ooMerchantLocationId = this.customerSession.locationId;

    if (this.walletApplied) {
      paymentData.walletBalanceApplied = this.walletBalanceApplied;
    } 

    if (this.giftCardApplied) {
      paymentData.giftCardBalanceApplied = this.giftCardBalanceApplied;
    } 

    if (this.order.details && (<any>this.order.details).fees && (<any>this.order.details).fees.length > 0) {
      (<any>this.order.details).fees.forEach(element => {
          let fee = new OOFees();
          fee.feeType = element.feeType;          
          fee.amount = element.amount;
          fee.name = element.name;
          ooFees.push(fee);
      });
      paymentData.fees = ooFees;
    }


    let paymentSetup = this.customerSession.getPaymentSetupInfo();
    if ((paymentSetup.paymentProvider == "Square" || paymentSetup.paymentProvider == "SQUAREPAYMENTS")) {     
      this.squareGooglePayService.processGooglePayV3(paymentData, paymentSetup.squareApplicationId, paymentSetup.squareLocationId);
    }
    else if (paymentSetup.paymentProvider == "Toast") {      
      console.log("Currently this integration does not support google payments.");
    }
    else {
      console.log("Currently this integration does not support google payments.");
    }
  } 

  openExternalInk(type) {
    var _terms = `https://customer.tapmango.com/Home/Terms?muid=${this.merchantGuid}`;
    var _privacy = `https://customer.tapmango.com/Home/Policy?muid=${this.merchantGuid}`;
    var url = (type === 'terms') ? _terms : _privacy;

    if (this.isMobile && this.isMobileApp) {
        this.utilService.openWithSystemBrowser(url);
    } else {
        window.open(url, '_blank');
    }
  }
}
