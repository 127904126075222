import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { ApplePaymentData } from './models/apple-payment-data';
import { PaymentService } from './payment.service';
import { PaymentAction } from './models/enum';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SquarePaymentPopupComponent } from './square-payment-popup/square-payment-popup.component';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class SquareApplePayService {
  private applePayAvailable$ = new BehaviorSubject<boolean>(false);
  private token$ = new BehaviorSubject<any>(null);
  private applePaymentData$ = new BehaviorSubject<ApplePaymentData>(null);
  squareLocationId$ = new BehaviorSubject<string>(null);
  squareAppId$ = new BehaviorSubject<string>(null);
  isMobile: boolean;
  isMobileApp: boolean;
  squareModalReference: NgbModalRef;

  constructor(private paymentService: PaymentService, private modalService: NgbModal, private util: UtilService) 
  {
    this.util.postAppSpecificRequestsToFrame();
    this.checkIfMobileApp();
    console.log('IS MOBILE APP: ' + this.isMobileApp);
  }

  checkIfMobileApp() {
    let mobileAppResult = this.util.checkIfMobileApp();
    this.isMobile = mobileAppResult.isMobile;
    this.isMobileApp = mobileAppResult.isMobileApp;   
  }

  checkApplePaySessionAvailable() {
    if ((<any>window).ApplePaySession) {
      console.log('The Apple Pay JS API is available');
      this.applePayAvailable$.next(true);
    }   
    else {
      console.log('No Appe Pay Session Found');
      this.applePayAvailable$.next(false);
    }
  }

  getApplePayAvailable() {
    return this.applePayAvailable$.getValue();
  }

  processApplePay() {    
    if (!this.getApplePayAvailable()) {
      return;
    }    
    var merchantId = this.paymentService.getMerchantId();
    var merchantLocationId = this.paymentService.getMerchantLocationId();     
    console.log('merchantId => ' + merchantId);
    console.log('merchantLocationId => ' + merchantLocationId);
    this.goToNewTab();
  }

  processApplePayV3(applePaymentData: ApplePaymentData, appId: string, locationId: string) {
    this.applePaymentData$.next(applePaymentData);
    this.squareAppId$.next(appId);
    this.squareLocationId$.next(locationId);
    this.checkIfMobileApp();
    if (this.isMobile && this.isMobileApp && this.paymentService.request.action == PaymentAction.PLACEORDER) {
      parent.postMessage(
        {
          action: 'initiate_apple_pay',
          paymentData: JSON.stringify(this.applePaymentData$.getValue()),
          etaMessage: this.paymentService.getEtaMessage(),
          orderType: this.paymentService.getOrderType()
        }, 
        sessionStorage.getItem("hostingapp")
      );
    }  
    else if (this.isMobile && this.isMobileApp && this.paymentService.request.action == PaymentAction.LOADWALLET) {
      parent.postMessage(
        {
          action: 'initiate_apple_pay_wallet',
          paymentData: JSON.stringify(this.applePaymentData$.getValue()),
          etaMessage: this.paymentService.getEtaMessage(),
          orderType: this.paymentService.getOrderType()
        }, 
        sessionStorage.getItem("hostingapp")
      );
    }
    else {
      this.processApplePay();
    }  
  } 

  goToNewTab() {  
    let popup = this.popupCenter(environment.SQUARE_WALLET_PAY_END_POINT, "Apple Pay", 500, 500);      
    timer(1000).subscribe(x => {
        console.log('Posting Message');
        popup.postMessage(JSON.stringify(this.applePaymentData$.getValue()), '*');           
    });
  }

  popupCenter (url, title, w, h) {
    // Fixes dual-screen position                              Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    const left = (window.screen.width/2)-(w/2) + dualScreenLeft;
    const top = (window.screen.height/2)-(h/2) + dualScreenTop;
    // For sandbox test - to remove
    this.squareAppId$.next('sandbox-sq0idb-TlWc_7Vw0TB3--xscWRGtA');
    this.squareLocationId$.next('RK2MJ33RTE049');
    return window.open(`${url}?appid=${this.squareAppId$.getValue()}&locationid=${this.squareLocationId$.getValue()}&paymentType=applePay`, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  } 
}


/*
try {
					  payments = window.Square.payments(appId, locationId);
					  const paymentRequest = buildPaymentRequest(payments);
					  console.log(payments);
					  console.log(paymentRequest);
					  var msg = {squarePaymentsObj : JSON.stringify(paymentRequest)}
					  window.parent.postMessage(msg, event.origin);
					  console.log('POSTED MESSAGE BACK');
					} catch (e) {
						console.error('Initializing Apple Pay failed', e);
						stopLoader();
					}
*/