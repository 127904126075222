import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { CartComponent } from './cart/cart.component';
import { LocationGuardService } from './location/location-guard.service';
import { CatalogComponent } from './catalog/catalog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MembershipsComponent } from './memberships/dashboard.component';
import { MembershipsListComponent } from './memberships/list/memberships-list.component';
import { ItemComponent } from './item/item.component';
import { LocationComponent } from './location/location.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { OrderReceiptComponent } from './order-receipt/order-receipt.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { PaymentAddComponent } from './payment-add/payment-add.component';
import { PaymentManageComponent } from './payment-manage/payment-manage.component';
import { RedeemablesComponent } from './redeemables/redeemables.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AssetResolver } from './merchant-assets/asset.resolver';
import { SpecialInstructionsComponent } from './special-instructions/special-instructions.component';
import { OrderGuidRedirectResolver } from './order-guid-redirect-resolver';
import { SquarePaymentComponent } from './square-payment/square-payment.component';
import { ReloadWalletComponent } from './reload-wallet/reload-wallet.component';
import { CustomerGuardService } from './customer-guard.service';
import { LoginComponent } from './login/login.component';
import { MerchantInitComponent } from './merchant-init/merchant-init.component';
import { MerchantGuidGuardService } from './merchant-guid-guard.service';
import { RegisterComponent } from './register/register.component';
import { OrderAheadComponent } from './order-ahead/order-ahead.component';
import { PaymentCardComponent } from './payment-card/payment-card.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { LoadWalletComponent } from './load-wallet/load-wallet.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConnectInStoreComponent } from './connect-in-store/connect-in-store.component';
import { RegisterKeytagComponent } from './register-keytag/register-keytag.component';
import { NMIPaymentComponent } from './nmi-payment/nmi-payment.component'
import { CloverPaymentComponent } from './clover-payment/clover-payment.component';
import { PaymentCheckoutComponent } from './payment-checkout/payment-checkout.component';
import { RefreshComponent } from './common/refresh/refresh.component';
import { OrderAheadGuard } from './guards/order-ahead.guard';
import { CatalogPickerComponent } from './catalog-picker/catalog-picker.component';
import { OrderTypePickerComponent } from './order-type-picker/order-type-picker.component';
import { OrderAheadPickerComponent } from './order-ahead-picker/order-ahead-picker.component';
import { RouteHelperComponent } from './route-helper/route-helper.component';
import { MembershipDetailsComponent } from './memberships/details/membership-details.component';
import { MembershipCheckoutComponent } from './memberships/checkout/membership-checkout.component';
import { MembershipCancellationComponent } from './memberships/cancellation/membership-cancellation.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { EmailVerificationGuardService } from './email-verification/email-verification-guard.service';
import { PhoneVerificationComponent } from './phone-verification/phone-verification.component';
import { GuestOrderStatusComponent } from './guest-order-status/status.component';
import { GuestOrderReceiptComponent } from './guest-order-receipt/receipt.component';
import { OrderHomeComponent } from './order-home/order-home.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { CartDetailsComponent } from './cart-details/cart-details.component';

const ooChildRoutes: Routes = [
    {
        path: 'order',
        resolve: [AssetResolver],
        children: [
            {
                path: 'location',
                component: LocationComponent
                //component: OrderHomeComponent
                //component: ItemDetailsComponent
                //component: CartDetailsComponent
            },
            {
                path: 'home',
                component: OrderHomeComponent
            },
            {
                path: 'item-details',
                component: ItemDetailsComponent
            },
            {
                path: 'catalog-picker',
                component: CatalogPickerComponent
            },
            {
                path: 'order-type-picker',
                component: OrderTypePickerComponent
            },
            {
                path: 'order-ahead-picker',
                component: OrderAheadPickerComponent
            },
            {
                path: 'catalog',
                canActivate: [LocationGuardService],
                children: [
                    {
                        path: '',
                        canActivate: [OrderAheadGuard],
                        component: CatalogComponent
                    },
                    {
                        path: 'item/:id',
                        component: ItemComponent
                    }
                ]
            },
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent
            },
            {
                path: 'connect-instore',
                component: ConnectInStoreComponent
            },
            {
                path: 'register-keytag',
                component: RegisterKeytagComponent
            },
            {
                path: 'register',
                component: RegisterComponent
            },
            {
                path: 'cart',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: CartComponent
            },
            {
                path: 'route-cart',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: RouteHelperComponent
            },
            {
                path: 'checkout',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: CheckoutComponent
            },
            {
                path: 'load-wallet',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: LoadWalletComponent
            },
            {
                path: 'redeemables',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: RedeemablesComponent
            },
            {
                path: 'special-instructions',
                canActivate: [CustomerGuardService],
                component: SpecialInstructionsComponent
            },
            {
                path: 'payment-card',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: PaymentCardComponent
            },
            {
                path: 'order-ahead',
                component: OrderAheadComponent
            },
            {
                path: 'payment-manage',
                canActivate: [CustomerGuardService],
                component: PaymentManageComponent
            },
            {
                path: 'payment-checkout',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: PaymentCheckoutComponent
            },
            {
                path: 'payment-add',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: PaymentAddComponent
            },
            {
                path: 'square-payment',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: SquarePaymentComponent
            },
            {
                path: 'clover-payment',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: CloverPaymentComponent
            },
            {
                path: 'nmi-payment',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: NMIPaymentComponent
            },
            {
                path: 'reload-wallet',
                canActivate: [CustomerGuardService, EmailVerificationGuardService],
                component: ReloadWalletComponent
            },
            {
                path: 'my-orders',
                canActivate: [CustomerGuardService],
                component: MyOrdersComponent
            },
            {
                path: 'order-receipt/:guid',
                canActivate: [CustomerGuardService],
                component: OrderReceiptComponent
            },
            {
                path: 'receipt/:guid',
                component: GuestOrderReceiptComponent
            },
            {
                path: 'order-status/:guid/:psa',
                canActivate: [CustomerGuardService],
                component: OrderStatusComponent
            },
            {
                path: 'status/:orderGuid',
                component: GuestOrderStatusComponent
            },
            {
                path: 'refresh',
                component: RefreshComponent
            },
            {
                path: 'email-verification',
                component: EmailVerificationComponent
            },
            {
                path: 'phone-verification',
                canActivate: [CustomerGuardService],
                component: PhoneVerificationComponent
            }
        ]
    }
];

const membershipsChildRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'connect-instore',
        component: ConnectInStoreComponent
    },
    {
        path: 'register-keytag',
        component: RegisterKeytagComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'list',
        component: MembershipsListComponent,
    },
    // {
    //     path: 'payment-manage',
    //     canActivate: [CustomerGuardService],
    //     component: PaymentManageComponent
    // },
    {
        path: 'details/:membershipId',
        component: MembershipDetailsComponent
    },
    {
        path: 'checkout',
        canActivate: [CustomerGuardService],
        component: MembershipCheckoutComponent
    },
    {
        path: 'cancel/:membershipId',
        canActivate: [CustomerGuardService],
        component: MembershipCancellationComponent
    },
    {
        path: 'payment-checkout',
        canActivate: [CustomerGuardService],
        component: PaymentCheckoutComponent
    },
    {
        path: 'payment-add',
        canActivate: [CustomerGuardService],
        component: PaymentAddComponent
    },
    {
        path: 'square-payment',
        canActivate: [CustomerGuardService],
        component: SquarePaymentComponent
    },
    {
        path: 'clover-payment',
        canActivate: [CustomerGuardService],
        component: CloverPaymentComponent
    },
    {
        path: 'nmi-payment',
        canActivate: [CustomerGuardService],
        component: NMIPaymentComponent
    }
];
  
const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuardService],
        component: MerchantInitComponent
    },
    {
        path: 'merchant/:guid',
        component: DashboardComponent,
        canActivateChild: [MerchantGuidGuardService],
        children: ooChildRoutes
    },
    {
        path: 'merchant/:guid/:locationid',
        component: DashboardComponent,
        canActivateChild: [MerchantGuidGuardService],
        children: ooChildRoutes
    },
    {
        path: 'memberships/:guid',
        component: MembershipsComponent,
        children: membershipsChildRoutes
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: true})],
  exports: [RouterModule],
})
export class AppRoutingModule { }

