import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OrderRoutingService } from 'src/app/order-routing.service';

@Component({
  selector: 'app-modal-delivery',
  templateUrl: './modal-delivery.component.html',
  styleUrls: ['./modal-delivery.component.scss']
})
export class ModalDeliveryComponent implements OnInit {

  constructor(private popoverContrl: PopoverController, private orderRoutingService: OrderRoutingService) { }

  ngOnInit() {
  }

  closeDeliveryModal() {
    this.popoverContrl.dismiss();
  }

  async startMyOrder() {
    await this.popoverContrl.dismiss().then(() => {
      this.orderRoutingService.goToOrderHome();
    });
    
  }
}
