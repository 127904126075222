import { Directive, ElementRef, Input, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appCustomCss]'
})
export class CustomCssDirective implements AfterViewInit, OnDestroy {

  @Input('appCustomCss') customClass: string;  // Custom class name from input

  private observer: MutationObserver;
  private interval: any;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.interval = setInterval(() => {
      console.log('CSS INTERVAL SET');
      this.addCustomCSS();
    }, 100);  // Poll every 100ms to check for the shadowRoot
  }

  private addCustomCSS() {
    const shadowRoot = this.el.nativeElement.shadowRoot;

    if (shadowRoot) {
      if(this.customClass == 'tm-custom-radio-bg') {
        const radioIcon = shadowRoot.querySelector('.radio-icon');
        if (radioIcon) {
          radioIcon.setAttribute('style', 'background: white !important');
          clearInterval(this.interval);
          console.log('CSS INTERVAL CLEARED');
        }
      }      
    }
  }

  // Cleanup to avoid memory leaks
  ngOnDestroy() {
    console.log('CSS INTERVAL CLEARED');
    clearInterval(this.interval);
  }
}